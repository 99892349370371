import React from 'react'
import UserFormFields from './user-fields'
import { useForm } from 'react-hook-form'
import { useSession, useSessionRequest } from '../../../stores/session'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import jwtDecode from 'jwt-decode'
import { Button, Heading, Element } from 'react-bulma-components'
import { RequestMessage } from '../../../stores/utils'

//TODO: i18n

const UserCompleteProfileForm = () => {
  const { navigate } = useI18next()
  //Session state & post request
  const [state, actions] = useSession()
  const [request] = useSessionRequest({
    key: 'post /users/complete-profile'
  })

  //Get & decode jwt token in url
  const location = useLocation()
  const query = queryString.parse(location.search)
  const token = query.token
  const decodedToken = token && jwtDecode(token)

  //Form
  const { control, handleSubmit } = useForm({
    shouldUseNativeValidation: true
  })
  const onSubmit = data => {
    data.token = token
    actions.completeProfile(data)
  }

  /*React.useEffect(() => {
    if (request && request.status === 'success') {
      actions.clearRequest({
        key: 'post /users/complete-profile'
      })
      navigate('/login')
    }
  }, [request])*/

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {
        request && request.status === 'success' ?
          <>
            <Heading size={5} textColor='dark' display='relative'>
              <Element renderAs='span' textColor='success' style={{ position: 'absolute', left: '-1.25em' }}>✓ </Element>Nous avons bien reçu votre demande d'inscription
            </Heading>
            <p>
              Votre demande est analysée par l'équipe de Exalto<br />
              La confirmation de l'inscription vous parviendra par email.
            </p>
          </>
          :
          <>
            <RequestMessage
              request={ request }
              messages={{
                error: <span>
                  {
                    request && request.message === 'Invalid token' &&
                      <span>
                        Ce lien n'est plus valide.
                        <br /><br />N'hésitez pas à nous contacter, nous serons ravis de vous aider.
                      </span>
                  }
                </span>
              }}

            />
            <UserFormFields
              title='Votre compte'
              control={ control }
              defaultValues={{ email: decodedToken && decodedToken.data && decodedToken.data.email }}
              disabled={{ email: true }}
            />
            <Button>Envoyer</Button>
          </>
      }
    </form>
  )
}

export default UserCompleteProfileForm